import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Box, Card, CardContent, Skeleton, Chip, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import frontendConfig from '../config/frontendConfig';
import ArticleIcon from '@mui/icons-material/Article';
import DateRangeIcon from '@mui/icons-material/DateRange';
import BusinessIcon from '@mui/icons-material/Business';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CampaignIcon from '@mui/icons-material/Campaign';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';

const Blog = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      fetchBlogPosts();
    }
  }, [user]);

  const fetchBlogPosts = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await fetch(`${frontendConfig.apiUrl}/api/blog/articles`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch articles');
      }
      
      const data = await response.json();
      setBlogPosts(data);
    } catch (error) {
      setError('Failed to load articles');
    } finally {
      setLoading(false);
    }
  };

  const renderArticleCard = (post) => {
    const isCharityArticle = post.title.includes('Monthly Spotlight:');

    return (
      <Card key={post.id} sx={{ 
        mb: 2,
        height: 'fit-content'
      }}>
        <CardContent sx={{ 
          p: 2,
          '&:last-child': {
            pb: 2
          }
        }}>
          {/* Date and Type Chips */}
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <Chip 
              icon={<DateRangeIcon />}
              label={new Date(post.createdAt).toLocaleDateString()}
              size="small"
              sx={{ backgroundColor: '#e3f2fd' }}
            />
            <Chip 
              icon={isCharityArticle ? <CampaignIcon /> : <LightbulbIcon />}
              label={isCharityArticle ? 'Charity Spotlight' : 'Community Insight'}
              size="small"
              sx={{ 
                backgroundColor: isCharityArticle ? '#e8f5e9' : '#fff3e0'
              }}
            />
          </Box>

          {/* Title Section */}
          <Typography variant="h6" component="h2" gutterBottom sx={{
            color: '#004aad',
            fontWeight: 'bold',
            mb: 1
          }}>
            {post.title}
          </Typography>

          {/* Content Preview */}
          <Typography variant="body2" color="text.secondary">
            {post.content.substring(0, 200)}...
          </Typography>

          {/* Footer */}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 2
          }}>
            <Typography variant="caption">
              {post.charityName || 'Helpful 7'}
            </Typography>
            <Button 
              variant="contained" 
              size="small"
              onClick={() => navigate(`/blog/${post.id}`)}
            >
              Read More
            </Button>
          </Box>
        </CardContent>
      </Card>
    );
  };

  if (!user) {
    return (
      <Container maxWidth="md" sx={{ 
        py: 8,
        minHeight: 'calc(100vh - 200px)'
      }}>
        <Typography variant="h2" component="h1" sx={{ 
          color: '#004aad',
          mb: 6,
          fontWeight: 'bold'
        }}>
          Helpful 7 Insights
        </Typography>

        {/* Loading skeleton effect */}
        {[1, 2, 3].map((n) => (
          <Card key={n} sx={{ mb: 4 }}>
            <CardContent>
              <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                <Skeleton variant="rounded" width={120} height={32} />
                <Skeleton variant="rounded" width={150} height={32} />
              </Box>
              <Skeleton variant="text" width="60%" height={40} sx={{ mb: 2 }} />
              <Skeleton variant="text" width="100%" height={20} />
              <Skeleton variant="text" width="100%" height={20} />
              <Skeleton variant="text" width="80%" height={20} />
            </CardContent>
          </Card>
        ))}

        {/* Updated Subscription Prompt */}
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          textAlign: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          p: 4,
          borderRadius: 2,
          boxShadow: 3,
          maxWidth: '90%',
          width: '500px'
        }}>
          <Typography variant="h4" component="h2" gutterBottom sx={{ color: '#004aad' }}>
            Subscribe to Access
          </Typography>
          <Typography variant="body1" sx={{ mb: 3 }}>
            Sign up to read our latest insights and articles about charitable giving and community impact.
          </Typography>
          <Button 
            variant="contained" 
            onClick={() => navigate('/subscribe')}
            sx={{ 
              backgroundColor: '#008a64',
              '&:hover': {
                backgroundColor: '#006e4f',
              }
            }}
          >
            Subscribe Now
          </Button>
        </Box>
      </Container>
    );
  }

  if (loading) {
    return (
      <Container maxWidth="md" sx={{ 
        py: 8,
        minHeight: 'calc(100vh - 200px)'
      }}>
        <Skeleton variant="text" width="60%" height={80} sx={{ mb: 6 }} />
        {[1, 2, 3].map((n) => (
          <Card key={n} sx={{ mb: 4 }}>
            <CardContent>
              <Skeleton variant="text" width="40%" height={40} sx={{ mb: 2 }} />
              <Skeleton variant="text" width="100%" height={20} />
              <Skeleton variant="text" width="100%" height={20} />
              <Skeleton variant="text" width="30%" height={20} />
            </CardContent>
          </Card>
        ))}
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md" sx={{ 
        textAlign: 'center',
        py: 8,
        minHeight: 'calc(100vh - 200px)'
      }}>
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ 
      py: 8,
      minHeight: 'calc(100vh - 64px)',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Typography variant="h2" component="h1" sx={{ 
        color: '#004aad',
        mb: 6,
        fontWeight: 'bold'
      }}>
        Helpful 7 Insights
      </Typography>
      
      <Box sx={{ 
        display: 'grid', 
        gap: 4,
        flex: 1
      }}>
        {blogPosts.map((post) => renderArticleCard(post))}
      </Box>
    </Container>
  );
};

export default Blog; 