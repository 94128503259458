import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, Button, Box, Paper, Chip, Divider } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DateRangeIcon from '@mui/icons-material/DateRange';
import BusinessIcon from '@mui/icons-material/Business';
import ArticleIcon from '@mui/icons-material/Article';
import CampaignIcon from '@mui/icons-material/Campaign';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import { useAuth } from '../contexts/AuthContext';
import frontendConfig from '../config/frontendConfig';

const BlogPost = () => {
  const [post, setPost] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      fetchPost();
    }
  }, [id, user]);

  const fetchPost = async () => {
    try {
      const response = await fetch(`${frontendConfig.apiUrl}/api/blog/posts/${id}`, {
        headers: {
          'Authorization': `Bearer ${user.token}`,
          'Accept': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch post: ${response.status}`);
      }

      const data = await response.json();
      setPost(data);
    } catch (error) {
      console.error('Error fetching blog post:', error);
      navigate('/blog');
    }
  };

  if (!user || !post) {
    return (
      <Container maxWidth="md" sx={{ py: 8 }}>
        <Typography>Loading...</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Button 
        onClick={() => navigate('/blog')} 
        sx={{ mb: 4 }}
        variant="outlined"
        startIcon={<ArrowBackIcon />}
        color="primary"
      >
        Back to Blog
      </Button>
      
      <Paper elevation={3} sx={{ p: 4, backgroundColor: '#fff' }}>
        <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
          <Chip 
            icon={<DateRangeIcon />}
            label={new Date(post.createdAt).toLocaleDateString()}
            sx={{ backgroundColor: '#e3f2fd' }}
          />
          <Chip 
            icon={post.charityName ? <CampaignIcon /> : <LightbulbIcon />}
            label={post.charityName ? `Charity Spotlight: ${post.charityName}` : 'Community Insight'}
            sx={{ 
              backgroundColor: post.charityName ? '#e8f5e9' : '#fff3e0',
              '& .MuiChip-icon': {
                color: post.charityName ? '#2e7d32' : '#ed6c02'
              }
            }}
          />
        </Box>

        <Typography variant="h3" component="h1" sx={{ 
          mb: 4,
          color: '#004aad',
          fontWeight: 'bold'
        }}>
          {post.title}
        </Typography>

        <Divider sx={{ my: 4 }} />

        <Typography variant="body1" sx={{ 
          whiteSpace: 'pre-wrap', 
          mb: 4,
          lineHeight: 1.8,
          fontSize: '1.1rem',
          color: '#333',
          '& p': {
            mb: 2
          }
        }}>
          {post.content}
        </Typography>

        <Divider sx={{ my: 4 }} />

        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 4 
        }}>
          <Typography variant="body2" sx={{ 
            color: 'text.secondary',
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}>
            <VolunteerActivismIcon sx={{ fontSize: 16 }} />
            Written by Helpful 7
          </Typography>
          {post.charityName && (
            <Typography variant="body2" sx={{
              color: 'text.secondary',
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}>
              <CampaignIcon sx={{ fontSize: 16 }} />
              Featured Charity: {post.charityName}
            </Typography>
          )}
        </Box>
      </Paper>
    </Container>
  );
};

export default BlogPost; 