import { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import frontendConfig from '../config/frontendConfig';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);

  // Initialize auth state from localStorage
  useEffect(() => {
    try {
      const storedToken = localStorage.getItem('token');
      const storedUser = localStorage.getItem('user');
      
      if (storedToken && storedUser) {
        try {
          const parsedUser = JSON.parse(storedUser);
          if (parsedUser) {
            setToken(storedToken);
            setUser(parsedUser);
          } else {
            localStorage.clear();
          }
        } catch {
          localStorage.clear();
        }
      }
    } catch {
      localStorage.clear();
    } finally {
      setLoading(false);
    }
  }, []);

  const login = async (email, password) => {
    try {
      // Clear any existing auth data first
      localStorage.clear();
      setUser(null);
      setToken(null);

      const response = await fetch(`${frontendConfig.apiUrl}/api/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          password
        })
      });

      if (!response.ok) {
        throw new Error('Login failed');
      }

      const data = await response.json();
      
      if (!data || !data.token || !data.user) {
        throw new Error('Invalid response data');
      }

      // Set new auth data
      localStorage.setItem('token', data.token);
      localStorage.setItem('user', JSON.stringify(data.user));
      
      setToken(data.token);
      setUser(data.user);

      // Set default authorization header
      axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
      
    } catch (error) {
      // Clear everything on error
      localStorage.clear();
      setUser(null);
      setToken(null);
      throw error;
    }
  };

  const logout = () => {
    localStorage.clear();
    setUser(null);
    setToken(null);
    delete axios.defaults.headers.common['Authorization'];
  };

  return (
    <AuthContext.Provider value={{ 
      user, 
      token,
      login,
      logout,
      loading 
    }}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}

export const getAuthHeader = () => {
  const token = localStorage.getItem('token');
  return token ? { Authorization: `Bearer ${token}` } : {};
}; 