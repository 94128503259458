import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';
import SecurityIcon from '@mui/icons-material/Security';

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 4, mb: 8 }}>
      <Typography 
        variant="h3" 
        component="h1" 
        gutterBottom
        sx={{
          color: '#004aad',
          textAlign: 'center',
          fontWeight: 'bold',
          mb: 4
        }}
      >
        Privacy Policy
      </Typography>

      <Paper 
        elevation={3} 
        sx={{ 
          p: 6,
          borderRadius: 2,
          maxWidth: 900,
          mx: 'auto'
        }}
      >
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <SecurityIcon sx={{ fontSize: 60, color: '#008a64', mb: 2 }} />
          <Typography variant="h6" color="text.secondary">
            Last Updated: {new Date().toLocaleDateString()}
          </Typography>
        </Box>

        <Typography variant="body1" paragraph sx={{ mb: 4 }}>
          At Helpful 7, we prioritize the privacy and security of our users. This Privacy Policy outlines our practices for collecting, using, and protecting your personal information.
        </Typography>

        <Typography variant="h5" sx={{ color: '#004aad', mb: 2, mt: 4 }}>
          1. Information We Collect
        </Typography>
        <Typography variant="body1" paragraph>
          We collect the following types of information:
          <ul>
            <li>Name and contact details</li>
            <li>Payment information for processing subscriptions</li>
            <li>Account preferences and settings</li>
            <li>Communication preferences</li>
            <li>Usage data and analytics</li>
          </ul>
        </Typography>

        <Typography variant="h5" sx={{ color: '#004aad', mb: 2, mt: 4 }}>
          2. How We Use Your Information
        </Typography>
        <Typography variant="body1" paragraph>
          Your information helps us:
          <ul>
            <li>Process your subscription</li>
            <li>Communicate about charitable initiatives</li>
            <li>Improve our services and user experience</li>
            <li>Send important updates about our platform</li>
            <li>Comply with legal obligations</li>
          </ul>
        </Typography>

        <Typography variant="h5" sx={{ color: '#004aad', mb: 2, mt: 4 }}>
          3. Data Protection
        </Typography>
        <Typography variant="body1" paragraph>
          We implement robust security measures to protect your data:
          <ul>
            <li>Encryption of sensitive information</li>
            <li>Regular security assessments</li>
            <li>Secure data storage practices</li>
            <li>Limited access to personal information</li>
          </ul>
        </Typography>

        <Typography variant="h5" sx={{ color: '#004aad', mb: 2, mt: 4 }}>
          4. Information Sharing
        </Typography>
        <Typography variant="body1" paragraph>
          We never sell your personal information. We may share data with:
          <ul>
            <li>Service providers who assist our operations</li>
            <li>Legal authorities when required by law</li>
          </ul>
        </Typography>

        <Typography variant="h5" sx={{ color: '#004aad', mb: 2, mt: 4 }}>
          5. Your Rights
        </Typography>
        <Typography variant="body1" paragraph>
          You have the right to:
          <ul>
            <li>Access your personal data</li>
            <li>Request data correction or deletion</li>
            <li>Opt-out of marketing communications</li>
            <li>Request data portability</li>
          </ul>
        </Typography>

        <Typography variant="h5" sx={{ color: '#004aad', mb: 2, mt: 4 }}>
          6. Cookies and Tracking
        </Typography>
        <Typography variant="body1" paragraph>
          We use cookies and similar technologies to:
          <ul>
            <li>Improve site functionality</li>
            <li>Analyze usage patterns</li>
            <li>Remember your preferences</li>
            <li>Enhance security</li>
          </ul>
        </Typography>

        <Typography variant="h5" sx={{ color: '#004aad', mb: 2, mt: 4 }}>
          7. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          For privacy-related inquiries, please contact us at:
          <Box sx={{ mt: 2 }}>
            <strong>Email:</strong> helpergroup07@gmail.com<br />
          </Box>
        </Typography>

        <Box sx={{ 
          mt: 6, 
          pt: 4, 
          borderTop: '1px solid #eee',
          textAlign: 'center'
        }}>
          <Typography variant="body2" color="text.secondary">
            This privacy policy is subject to change. We will notify users of any material changes via email or through our platform.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default PrivacyPolicy;