import { Box, Container, Typography, Paper, Grid, Card, CardContent } from '@mui/material';
import GroupsIcon from '@mui/icons-material/Groups';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import ArticleIcon from '@mui/icons-material/Article';

const About = () => {
  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      {/* Hero Section */}
      <Box sx={{ textAlign: 'center', mb: 8 }}>
        <Typography variant="h3" color="#004aad" gutterBottom>
          Making Impact Accessible
        </Typography>
        <Typography variant="h6" color="text.secondary" sx={{ maxWidth: '800px', mx: 'auto' }}>
          We believe everyone can make a difference, regardless of their schedule or budget.
        </Typography>
      </Box>

      {/* Main Content */}
      <Grid container spacing={4}>
        {/* Mission Card */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 4, backgroundColor: '#f8f9fa', borderRadius: 2 }}>
            <Typography variant="h4" gutterBottom color="#004aad">
              Our Mission
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem' }}>
              At Helpful 7, we understand that many people want to contribute to charitable causes but may face barriers of time or resources. That's why we've created a simple solution: for just $7 a month, you can be part of a community making real impact.
            </Typography>
          </Paper>
        </Grid>

        {/* How It Works Cards */}
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom color="#004aad" sx={{ mb: 4 }}>
            How It Works
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Card sx={{ height: '100%', backgroundColor: '#f8f9fa' }}>
                <CardContent>
                  <CalendarMonthIcon sx={{ fontSize: 40, color: '#004aad', mb: 2 }} />
                  <Typography variant="h6" gutterBottom>
                    Monthly Rotation
                  </Typography>
                  <Typography>
                    Each month, starting on the 7th, we feature a new charity. This monthly rotation ensures we can help various causes throughout the year.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ height: '100%', backgroundColor: '#f8f9fa' }}>
                <CardContent>
                  <VolunteerActivismIcon sx={{ fontSize: 40, color: '#004aad', mb: 2 }} />
                  <Typography variant="h6" gutterBottom>
                    Direct Impact
                  </Typography>
                  <Typography>
                    20% of all subscription revenue goes directly to our featured charity of the month. More subscribers means bigger donations!
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ height: '100%', backgroundColor: '#f8f9fa' }}>
                <CardContent>
                  <ArticleIcon sx={{ fontSize: 40, color: '#004aad', mb: 2 }} />
                  <Typography variant="h6" gutterBottom>
                    Stay Informed
                  </Typography>
                  <Typography>
                    Members receive 4+ articles monthly about our featured charity and related topics, keeping you connected to the causes you support.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ height: '100%', backgroundColor: '#f8f9fa' }}>
                <CardContent>
                  <GroupsIcon sx={{ fontSize: 40, color: '#004aad', mb: 2 }} />
                  <Typography variant="h6" gutterBottom>
                    Community Power
                  </Typography>
                  <Typography>
                    Together, our small individual contributions create significant collective impact. Join our community of helpers making a difference!
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        {/* Impact Summary */}
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ 
            p: 4, 
            mt: 4, 
            backgroundColor: '#004aad', 
            color: 'white',
            borderRadius: 2
          }}>
            <Typography variant="h5" gutterBottom align="center">
              Small Contributions, Big Impact
            </Typography>
            <Typography align="center" sx={{ fontSize: '1.1rem' }}>
              Join us in proving that when we come together, even small monthly contributions 
              can create meaningful change in our communities.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default About;