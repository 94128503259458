import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, Box, Card, CardContent, Skeleton } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import frontendConfig from '../config/frontendConfig';

const AdminBlogView = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      fetchBlogPosts();
    }
  }, [user]);

  const fetchBlogPosts = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${frontendConfig.apiUrl}/api/blog/articles`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch articles');
      }
      
      const data = await response.json();
      setBlogPosts(data);
    } catch (error) {
      console.error('Error fetching blog posts:', error);
      setError('Failed to load articles');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (articleId) => {
    if (!window.confirm('Are you sure you want to delete this article?')) {
      return;
    }

    try {
      const token = localStorage.getItem('token');
      
      const response = await fetch(`${frontendConfig.apiUrl}/api/blog/articles/${articleId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to delete article');
      }

      setBlogPosts(posts => posts.filter(post => post.id !== articleId));
    } catch (error) {
      console.error('Error deleting article:', error);
      setError('Failed to delete article');
    }
  };

  if (loading) {
    return (
      <Container maxWidth="md" sx={{ py: 8 }}>
        <Skeleton variant="text" width="60%" height={80} sx={{ mb: 6 }} />
        {[1, 2, 3].map((n) => (
          <Card key={n} sx={{ mb: 4 }}>
            <CardContent>
              <Skeleton variant="text" width="40%" height={40} sx={{ mb: 2 }} />
              <Skeleton variant="text" width="100%" height={20} />
              <Skeleton variant="text" width="100%" height={20} />
            </CardContent>
          </Card>
        ))}
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      <Typography variant="h4" sx={{ mb: 4 }}>
        Manage Articles
      </Typography>
      
      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}

      <Box sx={{ display: 'grid', gap: 4 }}>
        {blogPosts.length === 0 ? (
          <Typography>No articles found.</Typography>
        ) : (
          blogPosts.map((post) => (
            <Card key={post.id}>
              <CardContent>
                <Typography variant="h5" component="h2" gutterBottom>
                  {post.title}
                </Typography>
                <Typography variant="caption" color="text.secondary" sx={{ mb: 2, display: 'block' }}>
                  {new Date(post.createdAt).toLocaleDateString()}
                </Typography>
                <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
                  {post.content}
                </Typography>
                {post.charityName && (
                  <Typography variant="caption" color="text.secondary" sx={{ mt: 2, display: 'block' }}>
                    Charity: {post.charityName}
                  </Typography>
                )}
              </CardContent>
              <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <Button 
                  variant="contained"
                  color="error"
                  onClick={() => handleDelete(post.id)}
                >
                  Delete Article
                </Button>
              </Box>
            </Card>
          ))
        )}
      </Box>
    </Container>
  );
};

export default AdminBlogView; 