import { Box, Typography, Button, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Confetti from 'react-confetti';
import { useState, useEffect } from 'react';

function SubscriptionSuccess() {
  const navigate = useNavigate();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    
    // Stop confetti after 5 seconds
    const timer = setTimeout(() => setShowConfetti(false), 5000);

    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(timer);
    };
  }, []);

  return (
    <Box sx={{ 
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      mt: -4
    }}>
      {showConfetti && (
        <Confetti
          width={windowSize.width}
          height={windowSize.height}
          colors={['#004aad', '#00A572', '#003a8d', '#008a64']}
          numberOfPieces={200}
        />
      )}
      <Container maxWidth="sm">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            px: { xs: 2, sm: 0 }
          }}
        >
          <CheckCircleIcon 
            sx={{ 
              fontSize: { xs: 60, sm: 80 }, 
              color: '#00A572',
              mb: 3,
              animation: 'scaleIn 0.5s ease-out'
            }} 
          />
          
          <Typography 
            variant="h3" 
            component="h1" 
            sx={{ 
              color: '#004aad', 
              mb: 3,
              fontSize: { xs: '2rem', sm: '3rem' }
            }}
          >
            Thank you for subscribing!
          </Typography>
          
          <Typography 
            variant="h6" 
            sx={{ 
              color: '#666', 
              mb: 4,
              fontSize: { xs: '1rem', sm: '1.25rem' }
            }}
          >
            Your subscription and account have been activated.
          </Typography>

          <Box sx={{ 
            display: 'flex', 
            gap: { xs: 1, sm: 2 },
            flexDirection: { xs: 'column', sm: 'row' },
            width: { xs: '100%', sm: 'auto' }
          }}>
            <Button
              variant="contained"
              onClick={() => navigate('/blog')}
              fullWidth
              sx={{
                backgroundColor: '#004aad',
                py: 1.5,
                '&:hover': { backgroundColor: '#003a8d' }
              }}
            >
              GO TO BLOG
            </Button>
            
            <Button
              variant="outlined"
              onClick={() => navigate('/')}
              fullWidth
              sx={{
                color: '#00A572',
                borderColor: '#00A572',
                py: 1.5,
                '&:hover': {
                  borderColor: '#008a64',
                  backgroundColor: 'rgba(0, 165, 114, 0.04)'
                }
              }}
            >
              RETURN HOME
            </Button>
          </Box>
        </Box>
      </Container>

      <style>
        {`
          @keyframes scaleIn {
            from {
              transform: scale(0);
              opacity: 0;
            }
            to {
              transform: scale(1);
              opacity: 1;
            }
          }
        `}
      </style>
    </Box>
  );
}

export default SubscriptionSuccess; 