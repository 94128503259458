import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Typography,
  Card,
  Button,
  Paper,
  Grid,
  useTheme,
  useMediaQuery
} from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import FavoriteIcon from '@mui/icons-material/Favorite';
import charityImage from '../../assets/BTC.png';
import GroupsIcon from '@mui/icons-material/Groups';
import './home.css';
import { useNavigate } from 'react-router-dom';
import frontendConfig from '../../config/frontendConfig';

const Home = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentCharity, setCurrentCharity] = useState(null);
  const [subscriberCount, setSubscriberCount] = useState(0);
  const [currentGoal, setCurrentGoal] = useState(100);

  // Hardcoded charity of the month
  const charityOfTheMonth = {
    name: "The Bottomless Toy Chest ",
    description: "The Bottomless Toy Chest brings moments of joy and hope to children battling cancer by providing them with toys, crafts, and fun activities during their hospital stays. This nonprofit organization delivers empowering experiences that break up the monotony of treatment, lifting spirits and offering a sense of normalcy to young patients and their families. By carefully selecting items that are both safe and exciting, The Bottomless Toy Chest supports children’s emotional well-being, helping them feel stronger and more resilient throughout their difficult journeys. Through the generosity of donors and volunteers, the organization makes a meaningful difference, turning moments of fear and discomfort into experiences of joy, creativity, and empowerment, providing children with renewed hope and a reason to smile.",
    image: charityImage,
    website: "https://bottomlesstoychest.org/"
  };

  useEffect(() => {
    const fetchCharities = async () => {
      try {
        const response = await fetch(`${frontendConfig.apiUrl}/api/charities`, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
        
        if (!response.ok) {
          return;
        }
        
        const data = await response.json();
        setCurrentCharity(data[0]);
      } catch {
        // Silently fail in production
        return;
      }
    };

    fetchCharities();
  }, []);

  const fetchSubscriberCount = async () => {
    try {
      const response = await fetch(`${frontendConfig.apiUrl}/api/subscribers/count`);
      
      if (!response.ok) {
        return;
      }
      
      const data = await response.json();
      setSubscriberCount(data.count);
      setCurrentGoal(calculateGoal(data.count));
    } catch {
      // Silently fail in production
      return;
    }
  };

  useEffect(() => {
    // Initial fetch
    fetchSubscriberCount();

    // Set up polling every 30 seconds
    const interval = setInterval(fetchSubscriberCount, 30000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const calculateGoal = (count) => {
    if (count < 100) return 100;
    if (count < 1000) return 1000;
    if (count < 10000) return 10000;
    if (count < 100000) return 100000;
    return Math.ceil(count / 100000) * 100000;
  };

  const handleSubscribe = () => {
    navigate('/subscribe', { 
      state: { 
        fromSubscription: true,
      } 
    });
  };

  const getImageUrl = (imageUrl) => {
    if (!imageUrl) return '/placeholder-image.jpg';
    if (imageUrl.startsWith('http')) return imageUrl;
    return `http://localhost:3000${imageUrl}`;
  };

  // Progress calculation
  const progress = Math.min((subscriberCount / currentGoal) * 100, 100);

  return (
    <Container maxWidth="xl" sx={{ width: '90%', maxWidth: '1400px', mt: 4 }}>
      <Card 
        elevation={6} 
        sx={{ 
          textAlign: 'center', 
          py: 6,
          mb: 6,
          background: 'linear-gradient(135deg, #004aad 0%, #008a64 100%)',
          borderRadius: 4,
          position: 'relative',
          overflow: 'hidden'
        }}
      >
        <Box sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 0.1,
          background: 'repeating-linear-gradient(45deg, #fff, #fff 10px, transparent 10px, transparent 20px)'
        }}/>
        <PeopleIcon sx={{ 
          fontSize: 80, 
          color: 'white', 
          mb: 2,
          filter: 'drop-shadow(2px 2px 4px rgba(0,0,0,0.3))'
        }} />
        <Box sx={{ mb: 2 }}>
          <Typography 
            variant="h2" 
            component="span"
            sx={{ 
              color: 'white', 
              fontWeight: 'bold',
              textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
            }}
          >
            {subscriberCount.toLocaleString()}
          </Typography>
          <Typography 
            variant="h3" 
            component="span"
            sx={{ 
              color: 'rgba(255,255,255,0.7)', 
              fontWeight: 'bold',
              textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
              ml: 2
            }}
          >
            / {currentGoal.toLocaleString()}
          </Typography>
        </Box>
        <Typography 
          variant="h4" 
          sx={{ 
            color: 'white',
            textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
            fontWeight: 500
          }}
        >
          Helpers
        </Typography>
        <Box 
          sx={{ 
            mt: 3,
            mx: 'auto',
            width: '80%',
            height: '12px',
            bgcolor: 'rgba(255,255,255,0.2)',
            borderRadius: 10,
            overflow: 'hidden',
            boxShadow: 'inset 0 2px 4px rgba(0,0,0,0.1)'
          }}
        >
          <Box 
            sx={{ 
              width: `${progress}%`,
              height: '100%',
              bgcolor: 'white',
              borderRadius: 10,
              transition: 'width 0.8s ease-in-out',
              boxShadow: '0 0 10px rgba(255,255,255,0.5)'
            }}
          />
        </Box>
        <Typography 
          variant="body1" 
          sx={{ 
            color: 'white',
            mt: 2,
            opacity: 0.9
          }}
        >
          {`${Math.round(progress)}% to next goal`}
        </Typography>
      </Card>

      <Paper 
        elevation={0}
        sx={{ 
          mb: 6,
          backgroundColor: 'transparent'
        }}
      >
        <Typography variant="h4" sx={{ mb: 3, color: '#004aad', fontWeight: 'bold' }}>
          Charity of the Month
        </Typography>
        <Card sx={{ 
          p: 4, 
          backgroundColor: 'white',
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
          borderRadius: 2
        }}>
          <Grid container spacing={4}>
            {/* Image Section */}
            <Grid item xs={12} md={4}>
              <Box
                component="img"
                src={charityOfTheMonth.image}
                alt={charityOfTheMonth.name}
                sx={{
                  width: '100%',
                  height: '300px',
                  objectFit: 'cover',
                  borderRadius: 2,
                  boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
                }}
              />
            </Grid>
            
            {/* Content Section */}
            <Grid item xs={12} md={8}>
              <Typography variant="h5" sx={{ mb: 2, color: '#004aad', fontWeight: 'bold' }}>
                {charityOfTheMonth.name}
              </Typography>
              <Typography variant="body1" sx={{ mb: 3, fontSize: '1.1rem' }}>
                {charityOfTheMonth.description}
              </Typography>
              <Button 
                variant="contained"
                href={charityOfTheMonth.website}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ 
                  backgroundColor: '#004aad',
                  '&:hover': {
                    backgroundColor: '#003a8d'
                  }
                }}
              >
                Visit Website
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Paper>

      <Paper 
        elevation={3}
        sx={{ 
          p: 4,
          mb: 6,
          background: 'linear-gradient(135deg, #004aad 0%, #008a64 100%)',
          color: 'white',
          borderRadius: 2
        }}
      >
        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <GroupsIcon sx={{ fontSize: 80, mb: 2 }} />
            <Typography variant="h4" sx={{ mb: 2 }}>
              Together, We Make a Difference
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '1.1rem' }}>
              At Helpful 7, we believe in the power of collective impact. 
              When we all contribute a little, we can achieve something extraordinary. 
              Join our community of changemakers and help us create positive change, 
              one subscription at a time.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ 
              p: 4, 
              backgroundColor: 'white', 
              color: 'black',
              boxShadow: '0 4px 20px rgba(0,0,0,0.15)'
            }}>
              <Typography variant="h5" sx={{ mb: 2, color: '#004aad', fontWeight: 'bold' }}>
                Join Our Movement
              </Typography>
              <Typography variant="h4" sx={{ mb: 2, color: '#008a64' }}>
                $7/month
              </Typography>
              <Typography variant="body1" sx={{ mb: 3 }}>
                Subscribe now to join our community and gain access to exclusive content.
              </Typography>
              <Button 
                variant="contained" 
                size="large"
                fullWidth
                onClick={handleSubscribe}
                sx={{ 
                  backgroundColor: '#004aad',
                  fontSize: '1.2rem',
                  py: 1.5,
                  '&:hover': {
                    backgroundColor: '#003a8d'
                  }
                }}
              >
                Subscribe Now
              </Button>
            </Card>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default Home;