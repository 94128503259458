import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { 
  Container, 
  Typography, 
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button
} from '@mui/material';
import frontendConfig from '../config/frontendConfig';

function AdminScheduledArticles() {
  const [scheduledArticles, setScheduledArticles] = useState([]);
  const { token } = useAuth();

  useEffect(() => {
    fetchScheduledArticles();
  }, []);

  const fetchScheduledArticles = async () => {
    try {
      const response = await fetch(`${frontendConfig.apiUrl}/api/blog/scheduled-articles`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (!response.ok) throw new Error('Failed to fetch articles');
      
      const data = await response.json();
      setScheduledArticles(data);
    } catch (error) {
      console.error('Error fetching scheduled articles:', error);
    }
  };

  return (
    <Box sx={{ backgroundColor: '#f5f5f5', minHeight: '100vh', py: 4 }}>
      <Container maxWidth="lg">
        <Typography 
          variant="h3" 
          component="h1" 
          gutterBottom
          sx={{ 
            color: '#008a64',
            fontWeight: 'bold',
            mb: 4 
          }}
        >
          Scheduled Articles
        </Typography>

        <TableContainer component={Paper} sx={{ mt: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Scheduled Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {scheduledArticles.map((article) => (
                <TableRow key={article.id}>
                  <TableCell>{article.title}</TableCell>
                  <TableCell>{article.scheduledDate}</TableCell>
                  <TableCell>{article.status}</TableCell>
                  <TableCell>
                    <Button 
                      variant="contained"
                      size="small"
                      sx={{ 
                        backgroundColor: '#008a64',
                        '&:hover': {
                          backgroundColor: '#006a4d'
                        },
                        mr: 1
                      }}
                    >
                      Edit
                    </Button>
                    <Button 
                      variant="outlined"
                      size="small"
                      color="error"
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Box>
  );
}

export default AdminScheduledArticles; 