import React from 'react';
import { Box, Container, Typography, Link, useTheme, useMediaQuery } from '@mui/material';
import './footer.css';
import { Link as RouterLink } from 'react-router-dom';

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#008a64',
        color: 'white',
        py: 2,
        width: '100%',
        marginTop: 'auto'
      }}
    >
      <Container 
        maxWidth="xl"
        sx={{ 
          margin: '0 auto',
          width: '90%',
          maxWidth: '1400px',
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          alignItems: { xs: 'center', sm: 'center' },
          gap: { xs: 2, sm: 0 },
          padding: '0 !important'
        }}
      >
        <Typography 
          variant="body1" 
          sx={{ 
            fontSize: { xs: '0.9rem', sm: '1.1rem' },
            whiteSpace: 'nowrap',
            textAlign: { xs: 'center', sm: 'left' }
          }}
        >
          © 2024 Helpful 7. All rights reserved.
        </Typography>
        
        <Box sx={{ 
          display: 'flex', 
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: 1.5, sm: 3 },
          alignItems: { xs: 'center', sm: 'center' }
        }}>
          <Link 
            component={RouterLink} 
            to="/privacy-policy" 
            color="inherit" 
            sx={{ 
              fontSize: { xs: '0.9rem', sm: '1.1rem' },
              textDecoration: 'none',
              whiteSpace: 'nowrap'
            }}
          >
            Privacy Policy
          </Link>
          <Link 
            component={RouterLink} 
            to="/terms-of-service" 
            color="inherit" 
            sx={{ 
              fontSize: { xs: '0.9rem', sm: '1.1rem' },
              textDecoration: 'none',
              whiteSpace: 'nowrap'
            }}
          >
            Terms of Service
          </Link>
          <Link 
            component={RouterLink} 
            to="/contact-us" 
            color="inherit" 
            sx={{ 
              fontSize: { xs: '0.9rem', sm: '1.1rem' },
              textDecoration: 'none',
              whiteSpace: 'nowrap'
            }}
          >
            Contact Us
          </Link>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;