import React from 'react';
import { 
  Container, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  CardActions, 
  Button,
  Box 
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

function AdminDashboard() {
  const navigate = useNavigate();

  const adminActions = [
    {
      title: 'Generate Articles',
      description: 'Create new blog articles',
      path: '/admin/blog'
    },
    {
      title: 'Manage Articles',
      description: 'View and delete existing articles',
      path: '/admin/articles'
    },
    {
      title: 'Scheduled Articles',
      description: 'View and manage scheduled articles',
      path: '/admin/scheduled-articles'
    },
    {
      title: 'Manage Charities',
      description: 'Add or edit charity information',
      path: '/admin/charities'
    }
  ];

  return (
    <Box sx={{ backgroundColor: '#f5f5f5', minHeight: '100vh', py: 4 }}>
      <Container maxWidth="lg">
        <Typography 
          variant="h3" 
          component="h1" 
          gutterBottom
          sx={{ 
            color: '#008a64',
            fontWeight: 'bold',
            mb: 4 
          }}
        >
          Admin Dashboard
        </Typography>
        
        <Grid container spacing={3}>
          {adminActions.map((action) => (
            <Grid item xs={12} sm={6} md={4} key={action.title}>
              <Card 
                sx={{ 
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  boxShadow: 3,
                  '&:hover': {
                    boxShadow: 6
                  }
                }}
              >
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography 
                    variant="h5" 
                    component="h2" 
                    gutterBottom
                    sx={{ color: '#008a64' }}
                  >
                    {action.title}
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {action.description}
                  </Typography>
                </CardContent>
                <CardActions sx={{ p: 2 }}>
                  <Button 
                    variant="contained" 
                    fullWidth
                    onClick={() => navigate(action.path)}
                    sx={{ 
                      backgroundColor: '#008a64',
                      '&:hover': {
                        backgroundColor: '#006a4d'
                      }
                    }}
                  >
                    Go to {action.title}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

export default AdminDashboard; 