import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';
import GavelIcon from '@mui/icons-material/Gavel';

const TermsOfService = () => {
  return (
    <Container maxWidth="lg" sx={{ py: 4, mb: 8 }}>
      <Typography 
        variant="h3" 
        component="h1" 
        gutterBottom
        sx={{
          color: '#004aad',
          textAlign: 'center',
          fontWeight: 'bold',
          mb: 4
        }}
      >
        Terms of Service
      </Typography>

      <Paper 
        elevation={3} 
        sx={{ 
          p: 6,
          borderRadius: 2,
          maxWidth: 900,
          mx: 'auto'
        }}
      >
        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <GavelIcon sx={{ fontSize: 60, color: '#008a64', mb: 2 }} />
          <Typography variant="h6" color="text.secondary">
            Last Updated: {new Date().toLocaleDateString()}
          </Typography>
        </Box>

        <Typography variant="body1" paragraph sx={{ mb: 4 }}>
          Welcome to Helpful 7. By accessing or using our platform, you agree to be bound by these Terms of Service. Please read them carefully.
        </Typography>

        <Typography variant="h5" sx={{ color: '#004aad', mb: 2, mt: 4 }}>
          1. Acceptance of Terms
        </Typography>
        <Typography variant="body1" paragraph>
          By accessing our platform, you agree to:
          <ul>
            <li>Comply with all terms and conditions</li>
            <li>Be at least 18 years old or have parental consent</li>
            <li>Provide accurate and complete information</li>
            <li>Maintain the security of your account</li>
          </ul>
        </Typography>

        <Typography variant="h5" sx={{ color: '#004aad', mb: 2, mt: 4 }}>
          2. Subscription and Donations
        </Typography>
        <Typography variant="body1" paragraph>
          Regarding subscriptions and donations:
          <ul>
            <li>Monthly subscription fee is $7</li>
            <li>Donations are processed securely through our platform</li>
            <li>Subscriptions can be canceled at any time</li>
            <li>Refunds are handled on a case-by-case basis</li>
          </ul>
        </Typography>

        <Typography variant="h5" sx={{ color: '#004aad', mb: 2, mt: 4 }}>
          3. User Responsibilities
        </Typography>
        <Typography variant="body1" paragraph>
          Users must:
          <ul>
            <li>Maintain accurate account information</li>
            <li>Not share account credentials</li>
            <li>Report unauthorized account access</li>
            <li>Use the platform in compliance with laws</li>
          </ul>
        </Typography>

        <Typography variant="h5" sx={{ color: '#004aad', mb: 2, mt: 4 }}>
          4. Platform Rules
        </Typography>
        <Typography variant="body1" paragraph>
          Users are prohibited from:
          <ul>
            <li>Violating any applicable laws</li>
            <li>Interfering with platform operations</li>
            <li>Attempting unauthorized access</li>
            <li>Using the platform for illegal activities</li>
          </ul>
        </Typography>

        <Typography variant="h5" sx={{ color: '#004aad', mb: 2, mt: 4 }}>
          5. Intellectual Property
        </Typography>
        <Typography variant="body1" paragraph>
          All content on Helpful 7 is protected by:
          <ul>
            <li>Copyright laws</li>
            <li>Trademark rights</li>
            <li>Other intellectual property rights</li>
            <li>Usage restrictions</li>
          </ul>
        </Typography>

        <Typography variant="h5" sx={{ color: '#004aad', mb: 2, mt: 4 }}>
          6. Limitation of Liability
        </Typography>
        <Typography variant="body1" paragraph>
          Helpful 7:
          <ul>
            <li>Provides services "as is"</li>
            <li>Is not liable for indirect damages</li>
            <li>Maintains the right to modify services</li>
            <li>May terminate accounts for violations</li>
          </ul>
        </Typography>

        <Typography variant="h5" sx={{ color: '#004aad', mb: 2, mt: 4 }}>
          7. Changes to Terms
        </Typography>
        <Typography variant="body1" paragraph>
          We reserve the right to:
          <ul>
            <li>Modify these terms at any time</li>
            <li>Notify users of significant changes</li>
            <li>Require acceptance of updated terms</li>
            <li>Terminate service for non-compliance</li>
          </ul>
        </Typography>

        <Typography variant="h5" sx={{ color: '#004aad', mb: 2, mt: 4 }}>
          8. Contact Information
        </Typography>
        <Typography variant="body1" paragraph>
          For questions about these terms, contact us at:
          <Box sx={{ mt: 2 }}>
            <strong>Email:</strong> helpergroup07@gmail.com<br />
          </Box>
        </Typography>

        <Box sx={{ 
          mt: 6, 
          pt: 4, 
          borderTop: '1px solid #eee',
          textAlign: 'center'
        }}>
          <Typography variant="body2" color="text.secondary">
            By using Helpful 7, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
};

export default TermsOfService; 