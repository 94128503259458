import React, { useState, useContext } from 'react';
import { useAuth } from '../contexts/AuthContext';
import {
  Box,
  TextField,
  Button,
  CircularProgress,
  Typography,
  MenuItem,
  Divider,
  Paper
} from '@mui/material';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import frontendConfig from '../config/frontendConfig';

// Add country list
const countries = [
  { code: 'US', label: 'United States' },
  { code: 'CA', label: 'Canada' },
  { code: 'GB', label: 'United Kingdom' },
  { code: 'AU', label: 'Australia' },
  { code: 'NZ', label: 'New Zealand' },
  { code: 'IE', label: 'Ireland' },
  { code: 'DE', label: 'Germany' },
  { code: 'FR', label: 'France' },
  { code: 'ES', label: 'Spain' },
  { code: 'IT', label: 'Italy' },
  { code: 'NL', label: 'Netherlands' },
  { code: 'BE', label: 'Belgium' },
  { code: 'SE', label: 'Sweden' },
  { code: 'NO', label: 'Norway' },
  { code: 'DK', label: 'Denmark' },
  { code: 'FI', label: 'Finland' },
  { code: 'PT', label: 'Portugal' },
  { code: 'AT', label: 'Austria' },
  { code: 'CH', label: 'Switzerland' },
  { code: 'JP', label: 'Japan' },
  { code: 'KR', label: 'South Korea' },
  { code: 'SG', label: 'Singapore' },
  { code: 'HK', label: 'Hong Kong' },
  { code: 'AE', label: 'United Arab Emirates' },
  { code: 'BR', label: 'Brazil' },
  { code: 'MX', label: 'Mexico' },
  { code: 'IN', label: 'India' },
  { code: 'ZA', label: 'South Africa' }
];

// Sort countries alphabetically by label
countries.sort((a, b) => a.label.localeCompare(b.label));

const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const { login } = useAuth();
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    country: 'US',
    postalCode: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      // Create subscription
      const response = await fetch(`${frontendConfig.apiUrl}/api/subscriptions/create-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: formData.email,
          password: formData.password
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to create subscription');
      }

      const { clientSecret, customerId, userId, token } = await response.json();

      // Confirm payment
      const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: elements.getElement(CardNumberElement),
            billing_details: {
              email: formData.email,
            },
          }
        }
      );

      if (stripeError) {
        throw new Error(stripeError.message);
      }

      if (paymentIntent.status === 'succeeded') {
        // Log in the user
        await login(formData.email, formData.password);

        // Notify server of successful payment
        await fetch(`${frontendConfig.apiUrl}/api/subscriptions/payment-success`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({
            paymentIntentId: paymentIntent.id,
            userId: userId
          })
        });

        // Redirect to success page
        navigate('/subscription-success');
      }

    } catch (err) {
      setError('Payment failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const stripeElementStyle = {
    base: {
      fontSize: '16px',
      color: '#424770',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#9e2146',
      iconColor: '#9e2146'
    },
  };

  // Replace the single CardElement with individual elements
  const cardElementsContainer = {
    '& .StripeElement': {
      width: '100%',
      padding: '15px 14px',
      fontSize: '16px',
      backgroundColor: 'white',
      border: '1px solid #e0e0e0',
      borderRadius: '4px',
      '&--focus': {
        borderColor: '#004aad',
        boxShadow: '0 0 0 1px #004aad'
      },
      '&--invalid': {
        borderColor: '#dc3545'
      }
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
      <form onSubmit={handleSubmit}>
        <Typography variant="h5" sx={{ mb: 3, color: '#004aad', fontWeight: 'bold' }}>
          Account Details
        </Typography>
        
        <Box sx={{ mb: 4 }}>
          <TextField
            fullWidth
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            required
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Create Password"
            name="password"
            type="password"
            value={formData.password}
            onChange={handleChange}
            required
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Confirm Password"
            name="confirmPassword"
            type="password"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
          />
        </Box>

        <Divider sx={{ my: 4 }} />

        <Typography variant="h5" sx={{ mb: 3, color: '#004aad', fontWeight: 'bold' }}>
          Payment Details
        </Typography>

        <Box sx={{ mb: 3, display: 'flex', gap: 2 }}>
          <TextField
            select
            label="Country"
            name="country"
            value={formData.country}
            onChange={handleChange}
            sx={{ flex: 2 }}
          >
            {countries.map((country) => (
              <MenuItem key={country.code} value={country.code}>
                {country.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            label="Postal Code"
            name="postalCode"
            value={formData.postalCode}
            onChange={handleChange}
            required
            sx={{ flex: 1 }}
          />
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle2" sx={{ mb: 1, color: 'text.secondary' }}>
            Card Information
          </Typography>
          
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={cardElementsContainer}>
              <Typography variant="caption" sx={{ mb: 0.5, display: 'block' }}>
                Card Number
              </Typography>
              <CardNumberElement options={{ style: stripeElementStyle }} />
            </Box>

            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box sx={{ flex: 1, ...cardElementsContainer }}>
                <Typography variant="caption" sx={{ mb: 0.5, display: 'block' }}>
                  Expiration Date
                </Typography>
                <CardExpiryElement options={{ style: stripeElementStyle }} />
              </Box>

              <Box sx={{ flex: 1, ...cardElementsContainer }}>
                <Typography variant="caption" sx={{ mb: 0.5, display: 'block' }}>
                  Security Code
                </Typography>
                <CardCvcElement options={{ style: stripeElementStyle }} />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ 
          mb: 3, 
          p: 2, 
          backgroundColor: '#f8f9fa',
          borderRadius: 1,
          border: '1px solid #e0e0e0'
        }}>
          <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold' }}>
            Order Summary
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography>Monthly Subscription</Typography>
            <Typography>$7.00</Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold' }}>
            <Typography>Total</Typography>
            <Typography>$7.00/month</Typography>
          </Box>
        </Box>

        {error && (
          <Typography 
            color="error" 
            sx={{ 
              mb: 2, 
              p: 2, 
              bgcolor: '#ffebee', 
              borderRadius: 1,
              fontSize: '0.875rem'
            }}
          >
            {error}
          </Typography>
        )}

        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={loading || !stripe}
          sx={{
            py: 1.5,
            backgroundColor: '#004aad',
            '&:hover': { backgroundColor: '#003a8d' },
            fontSize: '1.1rem',
            fontWeight: 'bold'
          }}
        >
          {loading ? (
            <CircularProgress size={24} sx={{ color: 'white' }} />
          ) : (
            'Complete Purchase • $7/month'
          )}
        </Button>

        <Typography 
          variant="caption" 
          sx={{ 
            mt: 2, 
            display: 'block', 
            textAlign: 'center',
            color: 'text.secondary'
          }}
        >
          Your subscription will begin immediately after successful payment
        </Typography>
      </form>
    </Paper>
  );
};

export default PaymentForm;