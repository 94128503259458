import { useState, useEffect } from 'react';
import { 
  Container, Box, Typography, Button, Card, 
  CardContent, Divider, CircularProgress, Dialog,
  DialogTitle, DialogContent, DialogActions, TextField
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import frontendConfig from '../config/frontendConfig';

function SubscriptionDashboard() {
  const { user, token, loading: authLoading } = useAuth();
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [userEmail, setUserEmail] = useState(user?.email || '');

  useEffect(() => {
    if (!authLoading && !user) {
      navigate('/login');
      return;
    }
    
    if (user && token) {
      fetchSubscriptionDetails();
    }
  }, [user, token, authLoading, navigate]);

  const fetchSubscriptionDetails = async () => {
    try {
      const response = await fetch(`${frontendConfig.apiUrl}/api/subscriptions/status`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      setSubscription(data);
      setLoading(false);
    } catch (error) {
      setSubscription({
        status: 'incomplete',
        amount: '7.00',
        currentPeriodStart: new Date(),
        nextBillingDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000)
      });
      setLoading(false);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      const response = await fetch(`${frontendConfig.apiUrl}/api/subscriptions/cancel`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({})
      });
      
      if (response.ok) {
        const data = await response.json();
        setCancelDialog(false);
        setSubscription(prev => ({
          ...prev,
          status: 'cancelled'
        }));
        alert('Subscription cancelled successfully');
        await fetchSubscriptionDetails();
      } else {
        const error = await response.json();
        alert(error.message || 'Failed to cancel subscription');
      }
    } catch (error) {
      alert('Failed to cancel subscription. Please try again.');
    }
  };

  const handleUpdateEmail = async () => {
    try {
      const response = await fetch(`${frontendConfig.apiUrl}/api/users/update-email`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          newEmail,
          currentPassword
        })
      });

      if (response.ok) {
        localStorage.setItem('user_email', newEmail);
        const user = JSON.parse(localStorage.getItem('user'));
        user.email = newEmail;
        localStorage.setItem('user', JSON.stringify(user));
        setUserEmail(newEmail);
        setIsEditingEmail(false);
        setNewEmail('');
        setCurrentPassword('');
        alert('Email updated successfully');
        window.location.reload();
      } else {
        const error = await response.json();
        alert(error.message || 'Failed to update email');
      }
    } catch (error) {
      alert('Failed to update email');
    }
  };

  const handleUpdatePassword = async () => {
    try {
      const response = await fetch(`${frontendConfig.apiUrl}/api/users/update-password`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          currentPassword,
          newPassword
        })
      });

      if (response.ok) {
        setIsEditingPassword(false);
        setNewPassword('');
        setCurrentPassword('');
        alert('Password updated successfully');
      } else {
        const error = await response.json();
        alert(error.message || 'Failed to update password');
      }
    } catch (error) {
      alert('Failed to update password');
    }
  };

  const formatDate = (date) => {
    if (!date) return 'N/A';
    return new Date(date).toLocaleDateString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const activateSubscription = async () => {
    try {
      const response = await fetch(`${frontendConfig.apiUrl}/api/subscriptions/activate`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        setSubscription(data);
        window.location.reload();
      } else {
        alert('Failed to activate subscription');
      }
    } catch (error) {
      alert('Error activating subscription');
    }
  };

  if (authLoading || loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!user) return null;

  return (
    <Container maxWidth="md">
      <Box sx={{ py: 4 }}>
        <Typography variant="h4" sx={{ mb: 4, color: '#004aad' }}>
          Account Dashboard
        </Typography>

        {/* Account Details Card */}
        <Card sx={{ mb: 4 }}>
          <CardContent>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Account Details
            </Typography>
            <Divider sx={{ mb: 2 }} />
            
            <Box sx={{ mb: 3 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography>Email</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Typography>{user.email}</Typography>
                  <Button 
                    variant="outlined" 
                    size="small"
                    onClick={() => setIsEditingEmail(true)}
                  >
                    Change
                  </Button>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography>Password</Typography>
                <Button 
                  variant="outlined" 
                  size="small"
                  onClick={() => setIsEditingPassword(true)}
                >
                  Change Password
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>

        {/* Updated Subscription Status Card */}
        <Card sx={{ mb: 4 }}>
          <CardContent>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Subscription Status
            </Typography>
            <Divider sx={{ mb: 2 }} />
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <Typography>Status</Typography>
              <Typography sx={{ 
                color: subscription?.status === 'active' 
                  ? '#00A572' 
                  : subscription?.status === 'cancelled' 
                  ? '#FF4444'
                  : '#666',
                fontWeight: 'medium'
              }}>
                {subscription?.status?.toUpperCase() || 'No active subscription'}
              </Typography>
            </Box>

            {subscription?.status === 'active' && (
              <>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                  <Typography>Current Period Started</Typography>
                  <Typography>
                    {formatDate(subscription?.currentPeriodStart)}
                  </Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                  <Typography>Next Billing Date</Typography>
                  <Typography sx={{ color: '#004aad', fontWeight: 'medium' }}>
                    {formatDate(subscription?.nextBillingDate)}
                  </Typography>
                </Box>

                <Box sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between',
                  mb: 2,
                  backgroundColor: '#f5f5f5',
                  p: 2,
                  borderRadius: 1
                }}>
                  <Typography>Monthly Subscription</Typography>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    ${subscription?.amount || '7.00'}/month
                  </Typography>
                </Box>

                <Typography variant="body2" sx={{ color: 'text.secondary', mt: 2, fontSize: '0.8rem' }}>
                  Your subscription will automatically renew on {formatDate(subscription?.nextBillingDate)}.
                  You can cancel anytime before this date.
                </Typography>
              </>
            )}

            {subscription?.status === 'cancelled' && subscription?.accessUntil && (
              <Typography sx={{ color: '#666', mt: 1 }}>
                Access until: {formatDate(subscription.accessUntil)}
              </Typography>
            )}
          </CardContent>
        </Card>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {subscription?.status === 'active' && (
            <Button
              onClick={() => setCancelDialog(true)}
              color="error"
              variant="text"
              sx={{ 
                fontSize: '0.75rem',
                opacity: 0.6,
                '&:hover': { opacity: 0.8 }
              }}
            >
              Cancel Subscription
            </Button>
          )}
        </Box>
      </Box>

      {/* Email Update Dialog */}
      <Dialog open={isEditingEmail} onClose={() => setIsEditingEmail(false)}>
        <DialogTitle>Update Email</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2 }}>
            <TextField
              fullWidth
              label="New Email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              type="password"
              label="Current Password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEditingEmail(false)}>Cancel</Button>
          <Button onClick={handleUpdateEmail} variant="contained">Update</Button>
        </DialogActions>
      </Dialog>

      {/* Password Update Dialog */}
      <Dialog open={isEditingPassword} onClose={() => setIsEditingPassword(false)}>
        <DialogTitle>Update Password</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2 }}>
            <TextField
              fullWidth
              type="password"
              label="Current Password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              type="password"
              label="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEditingPassword(false)}>Cancel</Button>
          <Button onClick={handleUpdatePassword} variant="contained">Update</Button>
        </DialogActions>
      </Dialog>

      {/* Cancel Confirmation Dialog */}
      <Dialog open={cancelDialog} onClose={() => setCancelDialog(false)}>
        <DialogTitle>Cancel Subscription?</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to cancel your subscription? You'll continue to have access until the end of your current billing period.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCancelDialog(false)}>
            Keep Subscription
          </Button>
          <Button onClick={handleCancelSubscription} color="error">
            Cancel Subscription
          </Button>
        </DialogActions>
      </Dialog>

      {subscription?.status === 'incomplete' && (
        <Button
          variant="contained"
          color="primary"
          onClick={activateSubscription}
          sx={{ mt: 2 }}
        >
          Activate Subscription
        </Button>
      )}
    </Container>
  );
}

export default SubscriptionDashboard; 