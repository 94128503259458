import React from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  Paper,
  Link,
  useTheme
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';

const ContactUs = () => {
  const theme = useTheme();

  return (
    <Container maxWidth="lg" sx={{ py: 4, mb: 8 }}>
      <Typography 
        variant="h3" 
        component="h1" 
        gutterBottom
        sx={{
          color: '#004aad',
          textAlign: 'center',
          fontWeight: 'bold',
          mb: 4
        }}
      >
        Contact Us
      </Typography>

      <Paper 
        elevation={3} 
        sx={{ 
          p: 6,
          borderRadius: 2,
          background: 'linear-gradient(135deg, #004aad 0%, #008a64 100%)',
          color: 'white',
          textAlign: 'center',
          maxWidth: 700,
          mx: 'auto'
        }}
      >
        <EmailIcon sx={{ fontSize: 60, mb: 3 }} />
        
        <Typography 
          variant="h5" 
          gutterBottom
          sx={{ 
            fontWeight: 500,
            mb: 3
          }}
        >
          Get in Touch
        </Typography>
        
        <Typography 
          variant="body1" 
          paragraph
          sx={{ 
            fontSize: '1.1rem',
            mb: 4,
            lineHeight: 1.6
          }}
        >
          Have questions? We'd love to hear from you. 
          Send us an email and we'll respond as soon as possible.
        </Typography>

        <Box 
          sx={{ 
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            p: 3,
            borderRadius: 2,
            display: 'inline-block'
          }}
        >
          <Link 
            href="mailto:support@helpful7.com"
            sx={{ 
              color: 'white',
              textDecoration: 'none',
              fontSize: '1.2rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
          >
            <EmailIcon sx={{ fontSize: 24 }} />
            helpergroup07@gmail.com
          </Link>
        </Box>

        <Typography 
          variant="body2" 
          sx={{ 
            mt: 4,
            opacity: 0.9,
            fontSize: '0.9rem'
          }}
        >
          We typically respond to all inquiries within 24-48 hours during business days.
        </Typography>
      </Paper>
    </Container>
  );
};

export default ContactUs; 