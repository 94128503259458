import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Home from "./components/home/Home";
import Blog from "./pages/Blog";
import BlogPost from './pages/BlogPost';
import Subscribe from "./pages/Subscribe";
import SubscriptionSuccess from "./pages/SubscriptionSuccess";
import Login from "./components/login/Login";
import SubscriptionDashboard from "./pages/SubscriptionDashboard";
import PrivateRoute from "./components/PrivateRoute";
import AdminDashboard from './pages/AdminDashboard';
import AdminBlog from './pages/AdminBlog';
import AdminCharities from './pages/AdminCharities';
import AdminScheduledArticles from './pages/AdminScheduledArticles';
import PrivacyPolicy from './pages/legal/PrivacyPolicy';
import TermsOfService from './pages/legal/TermsOfService';
import ContactUs from './pages/legal/ContactUs';
import About from './pages/About';
import AdminBlogView from './pages/AdminBlogView';

function App() {
  return (
    <Router>
      <AuthProvider>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogPost />} />
          <Route path="/subscribe" element={<Subscribe />} />
          <Route path="/login" element={<Login />} />
          <Route path="/subscription-success" element={<SubscriptionSuccess />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <SubscriptionDashboard />
              </PrivateRoute>
            }
          />
          <Route 
            path="/admin" 
            element={
              <PrivateRoute>
                <AdminDashboard />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/admin/blog" 
            element={
              <PrivateRoute>
                <AdminBlog />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/admin/articles" 
            element={
              <PrivateRoute>
                <AdminBlogView />
              </PrivateRoute>
            } 
          />
          <Route path="/admin/charities" element={<AdminCharities />} />
          <Route path="/admin/scheduled-articles" element={<AdminScheduledArticles />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about" element={<About />} />
        </Routes>
        <Footer />
      </AuthProvider>
    </Router>
  );
}

export default App;
