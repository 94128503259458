import { useState } from 'react';
import { 
  Container, Box, TextField, Button, 
  Typography, Alert, CircularProgress,
  Select, MenuItem, FormControl,
  InputLabel, Divider, Paper
} from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import frontendConfig from '../config/frontendConfig';

function AdminBlog() {
  // Separate loading states for each form
  const [charityLoading, setCharityLoading] = useState(false);
  const [generalLoading, setGeneralLoading] = useState(false);
  
  // Form states
  const [charityName, setCharityName] = useState('');
  const [charityDescription, setCharityDescription] = useState('');
  const [theme, setTheme] = useState('');
  const [generalTitle, setGeneralTitle] = useState('');
  const [generalTopic, setGeneralTopic] = useState('');
  const [generalDescription, setGeneralDescription] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  
  // Get token from AuthContext
  const { token } = useAuth();

  const themes = [
    { value: 'impact', label: 'Impact Stories' },
    { value: 'behindScenes', label: 'Behind the Scenes' },
    { value: 'community', label: 'Community Spotlight' },
    { value: 'future', label: 'Future Vision' }
  ];

  const handleCharitySubmit = async (e) => {
    e.preventDefault();
    if (!token) {
      setError('Not authenticated');
      return;
    }

    setCharityLoading(true);
    setError('');
    setSuccess(false);

    try {
      const response = await fetch(`${frontendConfig.apiUrl}/api/blog/generate-articles`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ 
          charityName, 
          charityDescription,
          theme,
          articleType: 'charity'
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to generate articles');
      }

      const data = await response.json();
      setSuccess(true);
      // Clear form
      setCharityName('');
      setCharityDescription('');
      setTheme('');
    } catch (error) {
      console.error('Error generating articles:', error);
      setError(error.message || 'Failed to generate articles');
    } finally {
      setCharityLoading(false);
    }
  };

  const handleGeneralSubmit = async (e) => {
    e.preventDefault();
    
    // Validate fields
    if (!generalTitle || !generalTopic || !generalDescription) {
      setError('All fields are required');
      return;
    }

    setGeneralLoading(true);
    setError('');
    setSuccess(false);

    try {
      const response = await fetch(`${frontendConfig.apiUrl}/api/blog/generate-articles`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ 
          generalTitle,
          generalTopic,
          generalDescription,
          articleType: 'general'
        })
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to generate article');
      }

      setSuccess(true);
      // Clear form
      setGeneralTitle('');
      setGeneralTopic('');
      setGeneralDescription('');
    } catch (error) {
      setError(error.message || 'Failed to generate article');
    } finally {
      setGeneralLoading(false);
    }
  };

  return (
    <Container maxWidth="md" sx={{ py: 8 }}>
      {error && <Alert severity="error" sx={{ mb: 3 }}>{error}</Alert>}
      {success && <Alert severity="success" sx={{ mb: 3 }}>Article generated successfully!</Alert>}
      
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Paper sx={{ p: 3 }}>
          <Typography variant="h4" sx={{ mb: 4 }}>
            Generate Charity Article
          </Typography>

          <form onSubmit={handleCharitySubmit}>
            <TextField
              fullWidth
              label="Charity Name"
              value={charityName}
              onChange={(e) => setCharityName(e.target.value)}
              sx={{ mb: 2 }}
              required
            />

            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Theme</InputLabel>
              <Select
                value={theme}
                label="Theme"
                onChange={(e) => setTheme(e.target.value)}
                required
              >
                {themes.map((theme) => (
                  <MenuItem key={theme.value} value={theme.value}>
                    {theme.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              fullWidth
              label="Charity Description"
              value={charityDescription}
              onChange={(e) => setCharityDescription(e.target.value)}
              multiline
              rows={4}
              sx={{ mb: 3 }}
              required
            />

            <Button
              type="submit"
              variant="contained"
              disabled={charityLoading}
              sx={{ 
                backgroundColor: '#008a64',
                '&:hover': {
                  backgroundColor: '#006e4f',
                }
              }}
            >
              {charityLoading ? <CircularProgress size={24} /> : 'Generate Charity Article'}
            </Button>
          </form>
        </Paper>

        <Paper sx={{ p: 3 }}>
          <Typography variant="h4" sx={{ mb: 4 }}>
            Generate General Article
          </Typography>

          <form onSubmit={handleGeneralSubmit}>
            <TextField
              fullWidth
              label="Article Title"
              value={generalTitle}
              onChange={(e) => setGeneralTitle(e.target.value)}
              sx={{ mb: 2 }}
              required
            />

            <TextField
              fullWidth
              label="Topic"
              value={generalTopic}
              onChange={(e) => setGeneralTopic(e.target.value)}
              sx={{ mb: 2 }}
              required
              helperText="e.g., Volunteering Tips, Donation Impact, Community Building"
            />

            <TextField
              fullWidth
              label="Topic Description"
              value={generalDescription}
              onChange={(e) => setGeneralDescription(e.target.value)}
              multiline
              rows={4}
              sx={{ mb: 3 }}
              required
            />

            <Button
              type="submit"
              variant="contained"
              disabled={generalLoading}
              sx={{ 
                backgroundColor: '#008a64',
                '&:hover': {
                  backgroundColor: '#006e4f',
                }
              }}
            >
              {generalLoading ? <CircularProgress size={24} /> : 'Generate General Article'}
            </Button>
          </form>
        </Paper>
      </Box>
    </Container>
  );
}

export default AdminBlog; 