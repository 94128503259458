import React, { useState } from 'react';
import {
  Box,
  Card,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
} from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from '../components/PaymentForm';
import frontendConfig from '../config/frontendConfig';

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

const steps = ['Pricing', 'Features', 'Payment'];

const Subscribe = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleStepClick = (step) => {
    if (step < activeStep) {
      setActiveStep(step);
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Card sx={{ p: 4, textAlign: 'center' }}>
            <Typography variant="h4" gutterBottom color="#004aad">
              Join Our Community
            </Typography>
            <Typography variant="h2" sx={{ my: 3, color: '#004aad', fontWeight: 'bold' }}>
              $7<Typography component="span" variant="h5">/month</Typography>
            </Typography>
            <Typography variant="body1" sx={{ mb: 4, fontSize: '1.1rem' }}>
              Make a difference today
            </Typography>
            <Button 
              variant="contained"
              onClick={handleNext}
              sx={{ 
                backgroundColor: '#004aad',
                '&:hover': { backgroundColor: '#003a8d' },
                py: 1.5,
                px: 4,
                fontSize: '1.1rem'
              }}
            >
              Continue
            </Button>
          </Card>
        );

      case 1:
        return (
          <Card sx={{ p: 4, mt: 4 }}>
            <Typography variant="h4" gutterBottom color="#004aad" textAlign="center">
              Premium Features
            </Typography>
            <Box sx={{ mt: 3 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>✓ Contribute to Monthly Charity</Typography>
              <Typography variant="h6" sx={{ mb: 2 }}>✓ Access to Blog</Typography>
              <Typography variant="h6" sx={{ mb: 2 }}>✓ Part of a Community</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
              <Button onClick={handleBack}>
                Back
              </Button>
              <Button
                variant="contained"
                onClick={handleNext}
                sx={{ 
                  backgroundColor: '#004aad',
                  '&:hover': { backgroundColor: '#003a8d' }
                }}
              >
                Continue
              </Button>
            </Box>
          </Card>
        );

      case 2:
        return (
          <Card sx={{ p: 4, mt: 4 }}>
            <Elements stripe={stripePromise}>
              <PaymentForm />
            </Elements>
          </Card>
        );

      default:
        return null;
    }
  };

  return (
    <Box sx={{ 
      maxWidth: 600, 
      mx: 'auto',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      p: 2,
      mt: activeStep === 2 ? 4 : -4,
      ...(activeStep === 2 && {
        justifyContent: 'flex-start',
        pt: 8
      })
    }}>
      <Box>
        <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
          {steps.map((label, index) => (
            <Step 
              key={label} 
            >
              <StepLabel
                onClick={() => handleStepClick(index)}
                sx={{
                  cursor: index < activeStep ? 'pointer' : 'default',
                  '&:focus': {
                    outline: index < activeStep ? 'auto' : 'none'
                  }
                }}
                role={index < activeStep ? 'button' : 'text'}
                tabIndex={index < activeStep ? 0 : -1}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {renderStepContent(activeStep)}
      </Box>
    </Box>
  );
};

export default Subscribe;