import React, { useState } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Button, 
  Box, 
  IconButton, 
  Menu, 
  MenuItem,
  useTheme,
  useMediaQuery
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import logo from '../../assets/helpful_7_logo.png';
import './header.css';

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user, logout } = useAuth();
  const navigate = useNavigate();


  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    navigate('/');
    handleClose();
  };

  const isAuthenticated = !!user;

  return (
    <AppBar position="static" sx={{ backgroundColor: '#008a64' }}>
      <Toolbar sx={{ justifyContent: 'space-between', minHeight: '80px', position: 'relative' }}>
        <Link to="/" style={{ position: 'relative', zIndex: 2 }}>
          <img 
            src={logo} 
            alt="Helpful 7 Logo" 
            style={{ 
              height: '200px',
              marginTop: '-45px',
              marginBottom: '-50px',
              display: 'block',
              position: 'relative',
              zIndex: 1000
            }} 
          />
        </Link>

        {isMobile ? (
          <>
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleMenu}
              sx={{ 
                '& .MuiSvgIcon-root': { 
                  fontSize: '40px'
                }
              }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                sx: {
                  '& .MuiMenuItem-root': {
                    fontSize: '1.5rem',
                    padding: '15px 30px'
                  }
                }
              }}
            >
              <MenuItem component={Link} to="/" onClick={handleClose}>Home</MenuItem>
              <MenuItem component={Link} to="/about" onClick={handleClose}>About</MenuItem>
              <MenuItem component={Link} to="/blog" onClick={handleClose}>Blog</MenuItem>
              {/* Login/Subscribe menu items */}
              {!isAuthenticated && (
                [
                  <MenuItem key="login" component={Link} to="/login" onClick={handleClose}>Login</MenuItem>,
                  <MenuItem key="subscribe" component={Link} to="/subscribe" onClick={handleClose}>Subscribe</MenuItem>
                ]
              )}
              {/* Authenticated user menu items */}
              {isAuthenticated && (
                [
                  <MenuItem key="dashboard" component={Link} to="/dashboard" onClick={handleClose}>Dashboard</MenuItem>,
                  user?.isAdmin && <MenuItem key="admin" component={Link} to="/admin" onClick={handleClose}>Admin Dashboard</MenuItem>,
                  <MenuItem key="logout" onClick={handleLogout}>Logout</MenuItem>
                ]
              )}
            </Menu>
          </>
        ) : (
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Button 
              color="inherit" 
              component={Link} 
              to="/"
              sx={{ fontSize: '1.2rem' }}
            >
              Home
            </Button>
            <Button 
              color="inherit" 
              component={Link} 
              to="/about"
              sx={{ fontSize: '1.2rem' }}
            >
              About
            </Button>
            <Button 
              color="inherit" 
              component={Link} 
              to="/blog"
              sx={{ fontSize: '1.2rem' }}
            >
              Blog
            </Button>
            
            {!isAuthenticated ? (
              <>
                <Button 
                  color="inherit" 
                  component={Link} 
                  to="/login"
                  sx={{ fontSize: '1.2rem' }}
                >
                  Login
                </Button>
                <Button 
                  variant="contained"
                  component={Link}
                  to="/subscribe"
                  sx={{ 
                    fontSize: '1.2rem',
                    backgroundColor: '#004aad',
                    '&:hover': {
                      backgroundColor: '#003a8d'
                    }
                  }}
                >
                  Subscribe
                </Button>
              </>
            ) : (
              <>
                <Button 
                  color="inherit" 
                  component={Link} 
                  to="/dashboard"
                  sx={{ fontSize: '1.2rem' }}
                >
                  Dashboard
                </Button>
                {user?.isAdmin && (
                  <Button 
                    component={Link} 
                    to="/admin"
                    sx={{ 
                      fontSize: '1.2rem',
                      backgroundColor: '#004aad',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: '#003a8d'
                      }
                    }}
                  >
                    Admin Dashboard
                  </Button>
                )}
                <Button 
                  variant="contained"
                  onClick={handleLogout}
                  sx={{ 
                    fontSize: '1.2rem',
                    backgroundColor: '#004aad',
                    '&:hover': {
                      backgroundColor: '#003a8d'
                    }
                  }}
                >
                  Logout
                </Button>
              </>
            )}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;