import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  Card, 
  TextField, 
  Button, 
  Box,
  Grid,
  IconButton,
  Alert
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const API_URL = import.meta.env.VITE_API_URL;

function AdminCharities() {
  const [charities, setCharities] = useState([]);
  const [newCharity, setNewCharity] = useState({
    name: '',
    description: '',
    imageUrl: '',
    isActive: true
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [previewUrl, setPreviewUrl] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState(null);

  // Handle file upload
  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // Validate file size (e.g., max 5MB)
      if (file.size > 5 * 1024 * 1024) {
        setUploadError('File size must be less than 5MB');
        return;
      }

      // Validate file type
      if (!file.type.startsWith('image/')) {
        setUploadError('File must be an image');
        return;
      }

      const preview = URL.createObjectURL(file);
      setPreviewUrl(preview);
      setUploadError(null);
      setIsUploading(true);

      const formData = new FormData();
      formData.append('image', file);
      
      try {
        const response = await fetch(`${API_URL}/api/upload`, {
          method: 'POST',
          credentials: 'include',
          body: formData
        });
        
        if (!response.ok) {
          throw new Error('Failed to upload image');
        }
        
        const data = await response.json();
        setNewCharity(prev => ({
          ...prev,
          imageUrl: data.imageUrl
        }));
      } catch (err) {
        console.error('Upload error:', err);
        setUploadError('Failed to upload image');
      } finally {
        setIsUploading(false);
      }
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_URL}/api/charities`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...newCharity,
          monthActive: new Date()
        })
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to add charity');
      }
      
      setSuccess('Charity added successfully!');
      fetchCharities();
      setNewCharity({ name: '', description: '', imageUrl: '', isActive: true });
    } catch (err) {
      console.error('Submission error:', err);
      setError(err.message || 'Failed to add charity');
    }
  };

  // Fetch existing charities
  const fetchCharities = async () => {
    try {
      const response = await fetch(`${API_URL}/api/charities`);
      const data = await response.json();
      setCharities(data);
    } catch (err) {
      setError('Failed to fetch charities');
    }
  };

  useEffect(() => {
    fetchCharities();
  }, []);

  useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Typography variant="h3" component="h1" gutterBottom>
        Manage Charities
      </Typography>

      {/* Add New Charity Form */}
      <Card sx={{ p: 3, mb: 4 }}>
        <Typography variant="h5" gutterBottom>
          Add New Charity
        </Typography>
        
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}

        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Charity Name"
                value={newCharity.name}
                onChange={(e) => setNewCharity(prev => ({ ...prev, name: e.target.value }))}
                required
              />
            </Grid>
            
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Description"
                value={newCharity.description}
                onChange={(e) => setNewCharity(prev => ({ ...prev, description: e.target.value }))}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
                <Button
                  variant="contained"
                  component="label"
                  disabled={isUploading}
                  sx={{ mt: 2, width: 'fit-content' }}
                >
                  {isUploading ? 'UPLOADING...' : 'UPLOAD IMAGE'}
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={handleImageUpload}
                  />
                </Button>
                
                {uploadError && (
                  <Typography color="error" variant="body2">
                    {uploadError}
                  </Typography>
                )}
                
                {previewUrl && (
                  <Box
                    component="img"
                    src={previewUrl}
                    alt="Preview"
                    sx={{
                      maxWidth: 200,
                      maxHeight: 200,
                      objectFit: 'contain',
                      borderRadius: 1,
                      boxShadow: 1
                    }}
                  />
                )}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Button 
                type="submit" 
                variant="contained" 
                color="primary"
                size="large"
                disabled={isUploading}
                sx={{ mt: 2 }}
              >
                Add Charity
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>

      {/* List of Charities */}
      <Typography variant="h5" gutterBottom>
        Current Charities
      </Typography>
      <Grid container spacing={3}>
        {charities.map((charity) => (
          <Grid item xs={12} key={charity.id}>
            <Card sx={{ p: 2 }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={2}>
                  <Box
                    component="img"
                    src={charity.imageUrl ? `${API_URL}${charity.imageUrl}` : ''}
                    alt={charity.name}
                    sx={{ 
                      width: '100%',
                      maxHeight: 100,
                      objectFit: 'contain'
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography variant="h6">{charity.name}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {charity.description}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <IconButton color="primary">
                    <EditIcon />
                  </IconButton>
                  <IconButton color="error">
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default AdminCharities; 